<template>
  <div class="content-box">
    <div class="content-header">
      <el-input :value="storeName" @input="changeValue($event, 'storeName')" maxlength="50" class="input" clearable
        placeholder="请输入门店信息"></el-input>
      <el-input :value="nickname" @input="changeValue($event, 'nickname')" maxlength="25" class="input" clearable
        placeholder="请输入客户名称"></el-input>
      <el-input :value="productName" @input="changeValue($event, 'productName')" maxlength="25" class="input" clearable
        placeholder="请输入产品"></el-input>
      <el-input :value="productCode" @input="changeValue($event, 'productCode')" maxlength="25" class="input" clearable
        placeholder="请输入产品编码"></el-input>
      <el-date-picker :value="selectDate" @input="changeValue($event, 'selectDate')" format="yyyy-MM-dd HH:mm:ss"
        type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" class="select-date" start-placeholder="开始日期"
        end-placeholder="结束日期"></el-date-picker>
      <el-button type="plain" class="cotent-btn" @click="handleSearch">搜索</el-button>
      <div class="other-filter">
        <el-checkbox v-model="ysbChecked" @change="ysbCheckedChange">
          <div class="ysb-checkbox">
            养身帮连锁门店(指自营店、联营店、加盟店，不含合作店)
          </div>
        </el-checkbox>
      </div>
    </div>
    <div class="table-list">
      <el-table :data="tableData" style="width: 100%" max-height="700" :header-cell-style="headerCellStyle" border
        v-loading="loading" :cell-style="cellStyle">
        <el-table-column prop="storeName" label="门店信息">
        </el-table-column>
        <el-table-column prop="deviceId" label="设备ID">
        </el-table-column>
        <el-table-column prop="deviceName" label="设备型号">
        </el-table-column>
        <el-table-column label="产品分类名称">
          <template slot-scope="scope">
            <div class="label-wrap">{{ scope.row.presCategoryName ? scope.row.presCategoryName : scope.row.productName }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="qrCodeName" label="产品名称">
        </el-table-column>
        <el-table-column prop="productCode" label="产品编码">
        </el-table-column>
        <el-table-column prop="updateTime" label="操作时间">
        </el-table-column>
        <el-table-column prop="nickname" label="操作客户">
        </el-table-column>

      </el-table>

      <!-- 分页 -->
      <el-pagination style="margin-top: 20px" background @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="itemTotal"></el-pagination>

    </div>
  </div>
</template>

<script>
import { Watch, Mixins, Component } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";


import {
  productExpendList
} from "@/api/product";






@Component
export default class StoreList extends Mixins(loading, tablePagination) {
  nickname = "";
  storeName = "";
  productCode = "";
  tableData = [];
  itemTotal = 0;
  productName = "";
  selectDate = [];

  ysbChecked = false;

  mounted() {
    this.getData();
  }


  ysbCheckedChange() {
    this.getData();
  }

  getData() {
    this.showLoading();
    productExpendList({
      nickname: this.nickname,
      productName: this.productName,
      productCode: this.productCode,
      storeName: this.storeName,
      fromDate: this.selectDate && this.selectDate.toString().split(",")[0],
      toDate: this.selectDate && this.selectDate.toString().split(",")[1],
      pageNum: this.currentPage,
      pageSize: this.pageSize,
      isAllStore: Number(!this.ysbChecked)
    }).then(res => {
      this.hideLoading();
      this.tableData = res.data.list;
      this.itemTotal = res.data.total;
    }).catch(err => {
      this.hideLoading();
    });
  }

    //重置分页参数
  resetPages() {
    this.currentPage = 1;
  }

  // 头部输入
  changeValue(val, type) {
    this[type] = val;
  }

  // 搜索
  handleSearch() {
    this.resetPages();
    this.getData();
  }



}
</script>



<style lang="scss" scoped src="./style.scss">
</style>